<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="col-sm-4">
        <div class="d-flex px-4 pt-4 pb-0 pb-sm-4">
          <v-select
            v-if="tableSwitch === 'media'"
            v-model.number="mediaGallery"
            :items="[{ name: 'News', value: 0 }, { name: 'Event', value: 1 }]"
            item-value="value"
            item-text="name"
            outlined
            dense
            hide-details
            class="rounded-0"
            placeholder="Informationn Type"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-4 white">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            class="rounded-0"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-4">
        <v-card v-if="tableSwitch === 'banner'" class="d-flex align-center justify-center pa-4 fill-width fill-height secondary" dark outlined @click.prevent="BANNER_SHOW()">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          New Banner
        </v-card>
        <v-card v-else-if="tableSwitch === 'media'" class="d-flex align-center justify-center pa-4 fill-width fill-height secondary" dark outlined @click.prevent="MEDIA_SHOW()">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          New Media
        </v-card>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="tableSwitch === 'banner' ? headers : headersMedia"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.title`]="{ item }">
            <div class="py-2">
              <div>
                {{ item.title }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <div class="py-2">
              <div>
                {{ $localDT(item.created, 'display') }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.event_date_start`]="{ item }">
            <div class="py-2">
              <div>
                {{ $localDT(item.event_date_start, 'display') }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="py-2">
              <div>
                {{ item.name }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.registered_member`]="{ item }">
            <div class="py-2">
              <div>
                <div class="d-flex fill-width align-center">
                  <v-btn small class="text-capitalize mr-2 rounded-0" outlined color="primary" @click.prevent="EVENT_GET_REGISTERED(item)">
                    <v-icon small class="mr-1">
                      mdi-list-box-outline
                    </v-icon>
                    List
                  </v-btn>
                  Total Registered: {{ (parseInt(item.registered_member) || 0) + (parseInt(item.registered_non_member) || 0) }}
                </div>
                <div class="caption">
                  Member: {{ (parseInt(item.registered_member) || 0) }}, Non Member: {{ (parseInt(item.registered_non_member) || 0) }}
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.store_name`]="{ item }">
            <div>
              {{ item.store_name }}
            </div>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <div>
              {{ item.description }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div>
              {{ !parseInt(item.status) ? 'Tidak Aktif' : 'Aktif' }}
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['manager'], $store.state.user.role)" depressed small dark color="orange" class="rounded-0 text-capitalize" @click.prevent="tableSwitch === 'banner' ? BANNER_SHOW(item) : (tableSwitch === 'media' ? MEDIA_SHOW(item) : EVENT_SHOW(item))">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogMedia.show"
      persistent
      scrollable
      max-width="340"
    >
      <v-card v-if="dialogMedia.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogMedia.data.id ? 'Update Media' : 'Create New Media' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4" style="max-height: 75vh;">
          <div style="position: relative;" @click.prevent="UploadImage()">
            <v-img
              :src="dialogMedia.data.images"
              aspect-ratio="1"
              class="blue lighten-4 fill-width mb-4"
            />
            <div class="d-flex fill-width fill-height align-center justify-center caption c-pointer text-center" style="position: absolute;top: 0px;left:0px;">
              <span v-if="loadingImageUpload">
                uploading ...
              </span>
              <span v-else>
                {{ dialogMedia.data.images ? '+ Change Image' : '+ Add Image' }}<br>
                1:1
              </span>
            </div>
          </div>
          <v-text-field
            v-model="dialogMedia.data.title"
            outlined
            dense
            hide-details
            label="Title"
            class="mb-4 rounded-0"
          />
          <v-textarea
            v-model="dialogMedia.data.content"
            outlined
            dense
            rows="6"
            hide-details
            label="Content"
            class="mb-4 rounded-0"
          />
          <v-select
            v-model.number="dialogMedia.data.isgallery"
            :items="[
              { name: 'News', value: 0 },
              { name: 'Event', value: 1 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Media Type"
          />
          <v-select
            v-model.number="dialogMedia.data.status"
            :items="[
              { name: 'Aktif', value: 1 },
              { name: 'Tidak Aktif', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Publish Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogMedia.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            class="text-capitalize"
            @click="MEDIA_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogBanner.show"
      persistent
      scrollable
      max-width="340"
    >
      <v-card v-if="dialogBanner.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogBanner.data.id ? 'Update Banner' : 'Create New Banner' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4" style="max-height: 75vh;">
          <div style="position: relative;" @click.prevent="UploadImage()">
            <v-img
              :src="dialogBanner.data.image"
              :aspect-ratio="2/1"
              class="blue lighten-4 fill-width mb-4"
            />
            <div class="d-flex fill-width fill-height align-center justify-center caption c-pointer text-center" style="position: absolute;top: 0px;left:0px;">
              <span v-if="loadingImageUpload">
                uploading ...
              </span>
              <span v-else>
                {{ dialogBanner.data.image ? '+ Change Banner' : '+ Add Banner' }}
              </span>
            </div>
          </div>
          <v-text-field
            v-model="dialogBanner.data.name"
            outlined
            dense
            hide-details
            label="Title"
            class="mb-4 rounded-0"
          />
          <v-textarea
            v-model="dialogBanner.data.description"
            outlined
            dense
            rows="3"
            hide-details
            label="Description"
            class="mb-4 rounded-0 body-2"
          />
          <v-select
            v-model.number="dialogBanner.data.status"
            :items="[
              { name: 'Aktif', value: 1 },
              { name: 'Tidak Aktif', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Publish Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogBanner.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            class="text-capitalize"
            @click="BANNER_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEvent.show"
      persistent
      scrollable
      max-width="475"
    >
      <v-card v-if="dialogEvent.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogEvent.data.id ? 'Update Event' : 'Create New Event' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-4" style="max-height: 75vh;">
          <div style="position: relative;" @click.prevent="UploadImage()">
            <v-img
              :src="dialogEvent.data.image"
              :aspect-ratio="2/1"
              class="blue lighten-4 fill-width mb-4"
            />
            <div class="d-flex fill-width fill-height align-center justify-center caption c-pointer text-center" style="position: absolute;top: 0px;left:0px;">
              <span v-if="loadingImageUpload">
                uploading ...
              </span>
              <span v-else>
                {{ dialogEvent.data.image ? '+ Change Image' : '+ Add Image' }}
              </span>
            </div>
          </div>
          <v-text-field
            v-model="dialogEvent.data.title"
            outlined
            dense
            hide-details
            label="Title"
            class="mb-4 rounded-0"
          />
          <v-select
            v-model="dialogEvent.data.tournaments"
            :items="listTournaments"
            label="Tournaments"
            class="mb-4 rounded-0"
            outlined
            hide-details
            dense
            multiple
          >
            <template v-slot:prepend-item>
              <div class="py-2 px-4">
                <v-text-field
                  v-model="listTournament"
                  solo
                  dense
                  hide-details
                  label="+ Add Tournament"
                  class="rounded-pill"
                  append-icon="mdi-plus"
                  @click:append="listTournament ? (listTournaments.push(listTournament), dialogEvent.data.tournaments.push(listTournament), listTournament = '') : false"
                  @keypress.enter="listTournament ? (listTournaments.push(listTournament), dialogEvent.data.tournaments.push(listTournament), listTournament = '') : false"
                />
              </div>
              <v-divider class="mt-2" />
            </template>
            <template v-if="false" v-slot:append-item>
              <v-divider class="mt-2" />
              <v-list-item disabled>
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon>
                    mdi-food-apple
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content v-if="likesAllFruit">
                  <v-list-item-title>
                    Holy smokes, someone call the fruit police!
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-content v-else-if="likesSomeFruit">
                  <v-list-item-title>
                    Fruit Count
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ selectedFruits.length }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-content v-else>
                  <v-list-item-title>
                    How could you not like fruit?
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Go ahead, make a selection above!
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
          <v-textarea
            v-model="dialogEvent.data.content"
            outlined
            dense
            rows="5"
            hide-details
            label="Content / Description"
            class="mb-4 rounded-0 body-2"
          />
          <v-menu
            v-if="dialogEvent.show"
            ref="eventdatemenu"
            v-model="eventDateMenu"
            :close-on-content-click="false"
            :return-value.sync="dialogEvent.data.event_date_start"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dialogEvent.data.event_date_start"
                readonly
                outlined
                dense
                hide-details
                label="Event Date"
                class="mb-4 rounded-0"
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="dialogEvent.data.event_date_start"
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                class="text-capitalize"
                @click="eventDateMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                class="text-capitalize"
                @click="$refs.eventdatemenu.save(dialogEvent.data.event_date_start)"
              >
                Ok
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-select
            v-model.number="dialogEvent.data.status"
            :items="[
              { name: 'Aktif', value: 1 },
              { name: 'Tidak Aktif', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Publish Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogEvent.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            class="text-capitalize"
            @click="EVENT_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEventRegister.show"
      persistent
      scrollable
      max-width="475"
    >
      <v-card>
        <v-card-title class="body-1 justify-center font-weight-bold">
          Joined - {{ dialogEventRegister.title }}
        </v-card-title>
        <v-divider />
        <v-card-text class="py-2 px-4" style="max-height: 75vh;">
          <v-card flat class="d-flex align-center justify-center py-4 red--text caption my-2">
            <v-text-field
              v-model="SearchRegisteredMember"
              label="Search ..."
              solo
              hide-details
            />
          </v-card>
          <v-card v-if="!eventRegistered.length" flat class="d-flex align-center justify-center pa-8 red--text caption my-2">
            No data
          </v-card>
          <v-card v-for="(m, iM) in eventRegistered" :key="'member-' + iM" class="elevation-1 d-flex align-center justify-space-between pa-4 my-2">
            <div>
              <div class="d-flex align-center title">
                {{ m.customer_name }}
                <v-chip :color="parseInt(m.customer) ? 'primary' : 'grey lighten-2'" class="ml-2" x-small>
                  {{ parseInt(m.customer) ? 'Member' : 'Non-Member' }}
                </v-chip>
              </div>
              <div class="caption">
                {{ m.customer_email }}, {{ m.customer_whatsapp }}
              </div>
              <div class="caption">
                T-Shirt: {{ m.tshirt_size }}, Handycap: {{ m.handycap }}
              </div>
              <div class="caption">
                Tournaments: {{ m.tournament }}
              </div>
            </div>
            <div class="d-flex align-center">
              <v-btn :href="OpenWhatsapp(m.customer_whatsapp)" target="_blank" fab x-small class="elevation-0" color="green" dark>
                <v-icon color="white" small>
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogEventRegister.show = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
let searchInterval = null
export default {
  name: 'Feature',
  data: () => ({
    listTournament: '',
    listTournaments: [],
    tableSwitch: 'media',
    headers: [
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Description', value: 'description', sortable: false },
      { text: 'Status', value: 'status', align: 'center', sortable: false },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    headersMedia: [
      { text: 'Title', value: 'title' },
      { text: 'Created', value: 'created', sortable: false },
      { text: 'Status', value: 'status', align: 'center' },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    headersEvent: [
      { text: 'Title', value: 'title' },
      { text: 'Joined', value: 'registered_member' },
      { text: 'Event Date', value: 'event_date_start' },
      { text: 'Status', value: 'status', align: 'center' },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: null,
    options: {},
    mediaGallery: 0,
    dialogMedia: {
      show: false,
      data: null
    },
    dialogBanner: {
      show: false,
      data: null
    },
    eventDateMenu: false,
    dialogEvent: {
      show: false,
      data: null
    },
    loadingImageUpload: false,
    dialogEventRegister: {
      show: false,
      title: '',
      data: []
    },
    SearchRegisteredMember: ''
  }),
  computed: {
    eventRegistered () {
      return this.dialogEventRegister.data.filter(r => this.SearchRegisteredMemberFilter(r))
    },
    ...mapState({
      storeActive: state => state.storeActive
    })
  },
  watch: {
    tableSwitch (v) {
      if (v === 'banner') {
        this.BANNER_GET(this.tableSearch)
      } else if (v === 'media') {
        this.MEDIA_GET(this.tableSearch)
      } else {
        this.EVENT_GET(this.tableSearch)
      }
    },
    mediaGallery () {
      this.MEDIA_GET(this.tableSearch)
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        if (this.tableSwitch === 'banner') {
          this.BANNER_GET(this.tableSearch)
        } else if (this.tableSwitch === 'media') {
          this.MEDIA_GET(this.tableSearch)
        } else {
          this.EVENT_GET(this.tableSearch)
        }
      }, 300)
    },
    tableSearchStatus () {
      if (this.tableSwitch === 'banner') {
        this.BANNER_GET(this.tableSearch)
      } else if (this.tableSwitch === 'media') {
        this.MEDIA_GET(this.tableSearch)
      } else {
        this.EVENT_GET(this.tableSearch)
      }
    },
    storeActive (v) {
      if (v) {
        if (this.tableSwitch === 'banner') {
          this.BANNER_GET(this.tableSearch)
        } else if (this.tableSwitch === 'media') {
          this.MEDIA_GET(this.tableSearch)
        } else {
          this.EVENT_GET(this.tableSearch)
        }
      }
    }
  },
  mounted () {
    this.EVENT_GET()
  },
  methods: {
    SearchRegisteredMemberFilter (r) {
      const q = this.SearchRegisteredMember
      if (q) {
        const d = new RegExp(q.toLowerCase().trim(), 'gi')
        const customer = r.customer_name + ' ' + r.customer_email + ' ' + r.customer_whatsapp
        return customer ? customer.toLowerCase().trim().match(d) : false
      }
      return true
    },
    OpenWhatsapp (wa) {
      if (wa) {
        wa = parseInt(wa.substr(0, 1)) ? wa : '62' + wa.substr(1, 20)
        const w = wa.replace('+', '').trim()
        return 'https://api.whatsapp.com/send?phone=' + w + '&text='
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Tidak ada nomor Whatsapp!' })
      }
    },
    MEDIA_SHOW (media, gallery) {
      if (media) {
        this.dialogMedia.data = Object.assign({}, media)
      } else {
        this.dialogMedia.data = {
          id: null,
          isgallery: this.mediaGallery || 0,
          images: '',
          title: '',
          content: '',
          status: 1
        }
      }
      this.dialogMedia.show = true
    },
    MEDIA_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? ('&status=' + this.tableSearchStatus) : ''
      const gallery = this.mediaGallery === 0 || this.mediaGallery > 0 ? ('&gallery=' + this.mediaGallery) : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + ('&store=' + this.$store.state.storeActive) + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('feature/MEDIA_GET', query + (q ? ('&q=' + q) : '') + status + gallery).then((res) => {
        if (res.status) {
          this.table = res.data.data
          this.tableTotal = res.data.data.length
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    MEDIA_PROCESS () {
      const processedMedia = Object.assign({}, this.dialogMedia.data)
      if (!this.$validateName(processedMedia.title)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan judul yang valid!' })
        return false
      }
      if (!processedMedia.content) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan konten yang valid!' })
        return false
      }
      this.$store.dispatch('feature/MEDIA_PROCESS', processedMedia)
        .then((res) => {
          if (res.status) {
            this.MEDIA_GET(this.tableSearch)
            this.dialogMedia.show = false
            this.$store.dispatch('TOAST', { show: true, message: processedMedia.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    EVENT_SHOW (evt) {
      this.listTournament = ''
      if (evt) {
        this.dialogEvent.data = Object.assign({}, evt)
        this.dialogEvent.data.tournaments = this.$StrToArray(evt.tournaments)
        this.listTournaments = this.$StrToArray(evt.tournaments)
        this.dialogEvent.data.event_date_start = this.$localDT(evt.event_date_start, 'datedefault')
      } else {
        this.listTournaments = []
        this.dialogEvent.data = {
          id: null,
          title: '',
          image: '',
          content: '',
          tournaments: [],
          event_date_start: null,
          event_date_end: null,
          status: 1
        }
      }
      this.dialogEvent.show = true
    },
    EVENT_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + ('&store=' + this.$store.state.storeActive) + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('feature/EVENT_GET', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '')).then((res) => {
        if (res.status) {
          this.table = res.data.data
          this.tableTotal = res.data.data.length
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    EVENT_PROCESS () {
      const processedEvent = Object.assign({}, this.dialogEvent.data)
      processedEvent.tournaments = this.$ArrayToStr(processedEvent.tournaments)
      if (!this.$validateName(processedEvent.title)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan judul yang valid!' })
        return false
      }
      if (!processedEvent.content) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan konten yang valid!' })
        return false
      }
      this.$store.dispatch('feature/EVENT_PROCESS', processedEvent)
        .then((res) => {
          if (res.status) {
            this.EVENT_GET(this.tableSearch)
            this.dialogEvent.show = false
            this.$store.dispatch('TOAST', { show: true, message: processedEvent.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    EVENT_GET_REGISTERED (eventData) {
      this.$store.dispatch('feature/EVENT_REGISTER_GET', eventData.id)
        .then((res) => {
          if (res.status) {
            this.OpenEventRegistered(eventData, res.data.data)
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    OpenEventRegistered (eventData, data) {
      this.dialogEventRegister.data = data
      this.dialogEventRegister.title = eventData.title
      this.dialogEventRegister.show = true
    },
    BANNER_SHOW (banner) {
      if (banner) {
        this.dialogBanner.data = Object.assign({}, banner)
      } else {
        this.dialogBanner.data = {
          id: null,
          name: '',
          description: '',
          store: 0,
          status: 1,
          sorting: 1
        }
      }
      this.dialogBanner.show = true
    },
    BANNER_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + ('&store=' + this.$store.state.storeActive) + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('feature/BANNER_GET', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '')).then((res) => {
        if (res.status) {
          this.table = res.data.data
          this.tableTotal = res.data.data.length
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    BANNER_PROCESS () {
      const processedBanner = Object.assign({}, this.dialogBanner.data)
      if (!this.$validateName(processedBanner.name)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan nama yang valid!' })
        return false
      }
      if (!processedBanner.description) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan deskripsi yang valid!' })
        return false
      }
      if (this.$store.state.user.role !== 'root' && this.$store.state.user.role !== 'admin') {
        if (processedBanner.role === 'root' || processedBanner.role === 'admin' || processedBanner.role === 'manager') {
          this.$store.dispatch('TOAST', { show: true, message: 'Role ini tidak bisa diotorisasi!' })
          return false
        }
      }
      if (this.$role(['manager', this.$store.state.user.role]) && !parseInt(processedBanner.store)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Store Location belum dipilih!' })
        return false
      }
      this.$store.dispatch('feature/BANNER_PROCESS', processedBanner)
        .then((res) => {
          if (res.status) {
            this.BANNER_GET(this.tableSearch)
            this.dialogBanner.show = false
            this.$store.dispatch('TOAST', { show: true, message: processedBanner.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    UploadImage () {
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: $.tableSwitch === 'banner' ? 'banner' : ($.tableSwitch === 'media' ? 'media' : 'event')
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                if ($.tableSwitch === 'banner') {
                  $.dialogBanner.data.image = resUpload.data.data.secure_url
                } else if ($.tableSwitch === 'event') {
                  $.dialogEvent.data.image = resUpload.data.data.secure_url
                } else {
                  $.dialogMedia.data.images = resUpload.data.data.secure_url
                }
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>
